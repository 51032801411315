<template>
	<div style="background-color: #EBEEF5;padding-bottom: 10px;">
		<div class="sameWidth">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 0 0px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>专家中心</el-breadcrumb-item>
			</el-breadcrumb>
			<div style="margin:30px 0 30px 0px;text-align:left;display: table;width: 100%;padding:50px 0px;color: #595959;background-color: #fff;" >
				<div style="display: table-row;">
					<div style="padding: 0 20px;display:table-cell;width: 610px;">
						<h2>专家须知</h2>
						<p>非常感谢您申请成为招投研究院（广州）有限公司专家库的评标专家。在您注册之前，请您阅读本“须知”，以了解您所拥有的权利和肩负的责任。</p>
						<p>一、入选评标专家的条件：</p>
						<p class="contentBan">（一）具有较高的业务水平、良好的职业道德和认真负责的工作态度，公正诚信，廉洁自律；</p>
						<p class="contentBan">（二）具有中级专业技术职称或同等专业水平且从事相关领域工作满８年，或者具有高级专业技术职称或同等专业水平；</p>
						<p class="contentBan">（三）具有较丰富的评标实践经验和较强的实际工作能力，熟悉有关法律、法规、技术规范和标准；</p>
						<p class="contentBan">（四）身体健康，专家年龄一般不超过70岁；</p>
						<p class="contentBan">（五）对评审专家数量较少的专业，前款第（二）项、第（四）项所列条件可以适当放宽。</p>
						<p class="contentBan">（六）本人愿意以独立身份参加我司受托的项目评审工作并接受我司的监督管理。</p>
						<p>二、评审专家享有下列权利：</p>
						<p class="contentBan">（一）接受我司的聘请，为我司受托的评审项目提供评审方案或意见；</p>
						<p class="contentBan">（二）进行独立评审，提出专家方案或评审意见，不受任何单位和个人的干涉；</p>
						<p class="contentBan">（三）提出评审意见前，有权获得被评审项目的完整资料；</p>
						<p class="contentBan">（四）接受参加评审活动的劳务报酬；</p>
						<p class="contentBan">（五）法律、行政法规规定的其他权利。</p>
						<p>三、承诺提供以下申请材料，并保证材料真实有效:</p>
						<p class="contentBan">（一）证明本人身份的有效证件；</p>
						<p class="contentBan">（二）学历学位证书、专业技术职称证书或者具有同等专业水平的证明材料；</p>
						<p class="contentBan">（三）工作履历信息。</p>
					</div>
					<iframe :src="expertSrc" scrolling="no" style="width:480px;height:370px;vertical-align: top;border: none;">
					</iframe>
				</div>
			</div>
		</div>
		
	</div>
</template>


<style scoped>
	.contentBan{padding-left: 20px;}
</style>
<script>
export default {
	data() {
		return {
			expertSrc:this.baseUrl3+'#/loginWeb',
			screenWidth:1300,
		}
	},
	methods: {
	},
	mounted:function(){
		this.bus.$emit('headerImg',[{src:require('@/assets/images/bk/专家中心.jpg'),href:""}]);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
};
</script>
